import React, { useState, useEffect, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { GridRenderCellParams, GridFeatureModeConstant, GridFilterModel, GridValueGetterParams, GridSelectionModel, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { GridContainer } from '../../../styled/common.styles';
import { SavedReportTypeList, StructureTypeCategory } from '../../../configs/enums';
import { ActionPermissions, CustomPermission, LabelValue, ListType, NewReportDataType, OverwriteReportDataType, PropReportDataType, ReportResponse } from '../../../@types';
import { useGetStructurePaginatedDataByType } from '../../../queries/structure-query';
import { useGetStructureTypesByCategoryCode } from '../../../queries/structure-type-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Link } from '../../../components/atoms/Link';
import { useAddNewReport, useGetDefaultReportByReportType, useGetReportByCode, useUpdateReportByCode } from '../../../queries/report-query';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { generateReportURL } from '../../../utils/report';
import { TagCell, TagCellItem } from '../../../components/molecules/TagCell';
import { PrintLabel } from '../../../components/molecules/PintLabel';
import { PrintLabelEntityTypes } from '../../../@types/print-label.type';
import { PLATFORM_NAME } from '../../../configs/common';
import { checkListPagetFilterAvailability, formatDate, getLocalDateString } from '../../../utils/common';
import { columnSelectionItems } from '../../../constants/structure';
import { useGetRegionTypes } from '../../../queries/regionTypes-query';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { addNoneOptionToPresentationValues, getServicePresentationDescriptionFromValue } from '../../../utils/structure';

export const StructureList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const importSessionId = queryParams.get('importSessionId');
  const reportCode = queryParams.get('savedReportCode');
  const initialFilterModel = { columnField: 'importsessionId', operatorValue: 'equals', value: importSessionId };
  
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: importSessionId ? [initialFilterModel] : [{ columnField: 'isActive', operatorValue: 'is', value: true }] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [structureTypeList, setStructureTypeList] = useState<LabelValue[]>([]);
  const [reportName, setReportName] = useState('');
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [reportViewCode, setReportViewCode] = useState<string>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [structures, setStructures] = useState<Array<any>>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [regionTypeList, setRegionTypeList] = useState<DropdownItem[]>([]);

  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.StructureList);
  const { data: structureTypeListData } = useGetStructureTypesByCategoryCode(StructureTypeCategory.Operational);
  const { data, isLoading, isSuccess, refetch: refetchStructureData, isRefetching } = useGetStructurePaginatedDataByType(StructureTypeCategory.Operational, { filterModel, page, pageSize, sortModel }, reportColumnSelectionItems || columnSelectionItems);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const regionTypesQuery = useGetRegionTypes();

  useEffect(() => {
    if (regionTypesQuery.data) {
      const regionTypesItems = regionTypesQuery?.data?.map((item) => ({
        value: item.name,
        label: item.name
      })) || [];

      setRegionTypeList(regionTypesItems);
    }
  }, [regionTypesQuery.data]);

  useEffect(() => {
    setColumns([
      {
        field: 'id',
        headerName: 'Node ID',
        width: 100,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value.toString()
      },
      {
        field: 'name',
        headerName: 'Node Name',
        renderCell: (params: GridRenderCellParams) => 
          isUserHasPermission(ActionPermissions.Structure_Edit_View_Structure_Location, getUserPermissionsQuery.data) ?
            <Link href={`/structure/${params.row.id}`}>{params.row?.name}</Link> :
            params.row.name
        ,
        valueGetter: (params: GridValueGetterParams) => params.row?.name,
        width: 200
      },
      {
        field: 'nodeTypeName',
        headerName: 'Node Type',
        type: 'singleSelect',
        valueOptions: structureTypeList,
        valueGetter: (params: GridValueGetterParams) => params.row?.nodeTypeName,
        width: 200
      },
      {
        field: 'structureTags',
        headerName: 'Tags',
        renderCell: (params: GridRenderCellParams) => {
          if(params.row.structureTags)
            return (
              <TagCell tags={params.row.structureTags?.map((tag: TagCellItem) => tag)}/>
            );
          else
            return '';
        },
        valueGetter: (params) => params.row.structureTags?.map((tag: TagCellItem) => tag.title).join(', '),
        width: 200
      },
      {
        field: 'notes',
        headerName: 'Notes',
        valueGetter: (params: GridValueGetterParams) => params.row?.notes,
        width: 200
      },
      {
        field: 'referenceNo1',
        headerName: 'Reference Number 1', 
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.referenceNo1 && params.row.referenceNo1.referenceNo1
      },
      {
        field: 'referenceNo2',
        headerName: 'Reference Number 2', 
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.referenceNo2 && params.row.referenceNo2.referenceNo2
      },
      {
        field: 'referenceNo3',
        headerName: 'Reference Number 3', 
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.referenceNo3 && params.row.referenceNo3.referenceNo3
      },
      {
        field: 'presentationValue',
        headerName: 'Presentation',
        type: 'singleSelect',
        width: 200,
        valueOptions: addNoneOptionToPresentationValues(),
        valueGetter: (params: GridValueGetterParams) => getServicePresentationDescriptionFromValue(params.row.presentationValue)
      },
      {
        field: 'presentationDate',
        headerName: 'Presentation Date',
        type: 'date',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.presentationDate && getLocalDateString(params.row.presentationDate)
      },
      {
        field: 'siteSchema',
        headerName: 'Site Schema',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.siteSchema && params.row.siteSchema.name || ''
      },
      {
        field: 'regionType',
        headerName: 'Region Type',
        type: 'singleSelect',
        valueOptions: regionTypeList,
        valueGetter: (params: GridValueGetterParams) => params.row?.regionType,
        width: 200
      },
      {
        field: 'mainAddress',
        headerName: 'Main Address',
        width: 300,
        valueGetter: (params: GridValueGetterParams) => params.row?.mainAddress
      },
      {
        field: 'allAddresses',
        headerName: 'All Addresses',
        width: 300,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => params.row?.allAddresses
      },
      {
        field: 'mainContact',
        headerName: 'Main Contact',
        width: 300,
        valueGetter: (params: GridValueGetterParams) => params.row?.mainContact
      },
      {
        field: 'allContacts',
        headerName: 'All Contacts',
        sortable: false,
        width: 300,
        valueGetter: (params: GridValueGetterParams) => params.row?.allContacts
      },
      {
        field: 'tradingHrs',
        headerName: 'Trading Hours', 
        width: 300,
        valueGetter: (params: GridValueGetterParams) => 
        {
          if (params.row.tradingHrs) {
            const tradingHrs = params.row.tradingHrs?.map((data: { tradingHrs: string }) => data.tradingHrs);
            return tradingHrs.join(', ');
          }
          return '';
        }
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'singleSelect',
        width: 200,
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        valueGetter: (params: GridValueGetterParams) => params.row?.isActive ? 'Yes' : 'No'
      },
      {
        field: 'parentNodeId',
        headerName: 'Parent Node ID',
        valueGetter: (params: GridValueGetterParams) => params.row?.parentNodeId,
        width: 150,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
      },
      {
        field: 'parentName',
        headerName: 'Parent Node Name',
        valueGetter: (params: GridValueGetterParams) => params.row?.parentName,
        width: 250
      },
      {
        field: 'parentNodeTypeName',
        headerName: 'Parent Node Type',
        type: 'singleSelect',
        valueOptions: structureTypeList,
        valueGetter: (params: GridValueGetterParams) => params.row?.parentNodeTypeName,
        width: 150
      },
      {
        field: 'parentActive',
        headerName: 'Parent Active',
        type: 'singleSelect',
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        valueGetter: (params: GridValueGetterParams) => params.row?.parentActive ? 'Yes' : 'No',
        width: 250
      },
      {
        field: 'clientContractBrand',
        headerName: 'Client/Contract/Brand', 
        minWidth: 300,
        valueGetter: (params: GridValueGetterParams) => params.row?.clientContractBrand
      },
      {
        field: 'directChildCount',
        headerName: '# of Children (direct)',
        width: 200,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
        valueGetter: (params: GridValueGetterParams) => params.row?.directChildCount,
        filterable: false,
      },
      {
        field: 'totalChildCount',
        headerName: '# of Children (total)',
        width: 200,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
        valueGetter: (params: GridValueGetterParams) => params.row?.totalChildCount,
        filterable: false,
      },
      {
        field: 'goodPartCount',
        headerName: 'Quantity Stock (Good)',
        width: 200,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
        valueGetter: (params: GridValueGetterParams) => params.row?.goodPartCount
      },
      {
        field: 'partCount',
        headerName: 'Quantity Stock (Total)',
        width: 200,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
        valueGetter: (params: GridValueGetterParams) => params.row?.partCount
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        type: 'date',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row?.createdAt)
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row?.createdBy
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        type: 'date',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row?.updatedAt)
      },
      {
        field: 'updatedBy',
        headerName: 'Updated By',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row?.updatedBy
      },
      {
        field: 'importsessionId',
        headerName: 'Import Session ID',
        type:'string',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row?.importsessionId || ''
      },
      {
        field: 'printLabel', 
        headerName: '', 
        renderCell: (params: GridRenderCellParams) => ( 
          <PrintLabel entityId={Number(params.row.id) || 0} entityType={PrintLabelEntityTypes.Structure}/>
        ),
        filterable: false
      }   
    ]);
  }, [permissions, structureTypeList, regionTypeList]);

  const onFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    if (importSessionId && !newFilterModel.items.length) {
      setFilterModel({ items: [ initialFilterModel, ...newFilterModel.items ] });
    } else {
      if(newFilterModel.items.length)
        setFilterModel(newFilterModel);
      else 
        setFilterModel({ items: [{ columnField: 'isActive', operatorValue: 'is', value: true }] });
    }
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    !reportCode && defaultReportData.refetch();
  }, [SavedReportTypeList.StructureList]);

  useEffect(() => {
    if (!reportCode && !importSessionId && defaultReportData.data && defaultReportData.data?.length > 0) {
      setFilterModel(defaultReportData.data[0].dataGridProperties?.filterModel);
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
      setPageSize(defaultReportData.data[0].dataGridProperties?.pageSize);
    }   
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);


  useEffect(() => {
    if (reportQueryData) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Structure | ${PLATFORM_NAME}`;

      setFilterModel(reportQueryData.dataGridProperties?.filterModel);
      setSortModel(reportQueryData.dataGridProperties?.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setPageSize(reportQueryData.dataGridProperties?.pageSize);
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
    }   
  }, [reportQueryData, reportViewCode]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: filterModel,
        pageSize: pageSize,
        sortModel: sortModel || []
      },
      reportTypeName: SavedReportTypeList.StructureList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [filterModel, pageSize, isDefault, reportName, sortModel]);

  useEffect(() => {
    if(filterModel.items.length === 0 || checkListPagetFilterAvailability(filterModel) || sortModel?.some(sort => sort.field)) {
      refetchStructureData();
    }
  }, [filterModel, sortModel]);

  useEffect(() => {
    if (page || pageSize || filterModel || sortModel || !reportCode || (reportCode && reportQueryData)) {
      refetchStructureData();
    }
  }, [page, pageSize, reportCode, isDefault, reportColumnSelectionItems, reportQueryData]);

  useEffect(() => {
    setStructures(data?.data || []);
    setRowCountState(data?.total || 0);
  }, [isLoading, isSuccess, data]);

  useEffect(() => {
    if (structureTypeListData) {
      setStructureTypeList(structureTypeListData.map(listData => (
        { value: listData.name, label: listData.name }
      )));
    }
  }, [structureTypeListData]);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };

  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overwriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overwriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true); 
    navigate(reportURL, { replace: true });
  };

  return (
    <GridContainer>
      <Grid>
        <Snackbar  
          open={openToast} 
          autoHideDuration={4000} 
          message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
          onClose={()=> setOpenToast(false)}
        />
      </Grid>
      <CustomDataGrid
        columns={columns}
        rows={structures}
        exportFileName={ListType.StructureList}
        enableCSVExport={isUserHasPermission(ActionPermissions.Structure_Export, permissions)}
        columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : columnSelectionItems}
        filterMode={GridFeatureModeConstant.server}
        onFilterModelChange={(filterModel: GridFilterModel) => {
          onFilterChange(filterModel);
        }}
        loading={isLoading || isRefetching}
        rowCount={rowCountState}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
        }}
        sortModel={sortModel}
        sortingMode={GridFeatureModeConstant.server}
        onSortModelChange={handleSortModelChange}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        initialState={{
          columns: {
            columnVisibilityModel: {
              parentNodeId: false,
              referenceNumbers: false,
              status: false,
            },
          },
        }}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true }
          }
        }}
        structureTypeCategory={StructureTypeCategory.Operational}
        filterModel={filterModel}
        newReportData={newReportData} 
        handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
        handleOverwriteReport={(overwriteReportData: OverwriteReportDataType) => overwriteReport(overwriteReportData)}
        enableSavedReport={true}
        reportCode={reportViewCode}
        handleColumnsChange={handleColumnsChange}
        handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
        getRowHeight={() => 'auto'}
      />
    </GridContainer>
  );
};
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const SAMPLE_SERVICE_TYPE = {
  code: '',
  name: '',
  isCheckInAvailable: true,
  isActive: true
};
  
export const SAMPLE_ACTION_TYPE = {
  code: '',
  name: '',
  isActive: true
};
  
export const SAMPLE_PROCESS_TYPE = {
  code: '',
  name: '',
  isActive: true
};

export const SAMPLE_INTERACTIONS_SERVICE_COMMON_TYPE = {
  serviceTypes: [SAMPLE_SERVICE_TYPE],
  actionTypes: [SAMPLE_ACTION_TYPE],
  processTypes: [SAMPLE_PROCESS_TYPE]
};

export const TRIGGER_POINT_MENU_OPTIONS = [
  { value: 'status-change', code:'status-change', label: 'Status Change' },
  { value: 'system-event', code:'system-event', label: 'System Event' }
];

export const SYSTEM_EVENT_MENU_OPTIONS = [
  { value:'check-in', label: 'Check In' }, 
  { value:'check-out', label: 'Check Out' },
  { value:'part-action-execution', label: 'Part Action Execution' },
  { value:'task-execution', label: 'Task Execution' }
];

export const TEMPLATE_MENU_OPTIONS = [
  { value: 1, label: 'Template 1' },
  { value: 2, label: 'Template 2' }
];

export const SAMPLE_INTERACTION_RULE_OBJECT = {
  id: 0,
  name: '',
  serviceTypes: [],
  actionTypes: [],
  processTypes: [],
  client: [],
  contract: [],
  brand: [],
  nodeHierarchy: '',
  priorities: [],
  priorityCodes: [],
  regions: [],
  models: [],
  triggerPoint: '',
  textReferenceType: [],
  toTextMatch: '',
  templateId: -1,
  fromStatusType: [],
  toStatusType: [],
  eventType: '',
  partActionType: [],
  partActionPartTypes: [],
  hasPartActionConditions: false,
  partActionConditions: [],
  taskType: [],
  notes: '',
  isActive: false
};

export const SAMPLE_PRIORITY_OBJECT = {
  client: [],
  contract: [],
  brand: [],
  serviceTypes: []
};

export const TEXT_REFERENCE_MENU_OPTIONS = [
  { value: 'service-ref1', label: 'Service Reference 1' },
  { value: 'service-ref2', label: 'Service Reference 2' },
  { value: 'service-ref3', label: 'Service Reference 3' },
  { value: 'structure-ref1', label: 'Structure Reference 1' },
  { value: 'structure-ref2', label: 'Structure Reference 2' },
  { value: 'structure-ref3', label: 'Structure Reference 3' },
];

export const INTERACTION_AVAILABLE_DATA = {
  load: false,
  interactions: false
};

export const INTERACTION_CHECKIN_DATA = {
  travelStart: '',
  noteText: '',
  selectedServices: []
};

export const INTERACTION_CHECKOUT_DATA = {
  serviceStatus: '',
  noteText: '',
  offSiteTime: ''
};

export const interactionTemplateColumnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'Template',
    value: [
      {
        title: 'ID',
        field: 'id',
        value: true
      },
      {
        title: 'Name',
        field: 'name',
        value: true
      },
      {
        title: 'Created At',
        field: 'createdAt',
        value: false
      },
      {
        title: 'Created By',
        field: 'createdBy',
        value: true
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        value: false
      },
      {
        title: 'Updated By',
        field: 'updatedBy',
        value: true
      },
      {
        title: 'Is Active',
        field: 'isActive',
        value: true
      }
    ]
  }
];

export const interactionRuleColumnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'Rules',
    value: [
      {
        title: 'ID',
        field: 'id',
        value: true
      },
      {
        title: 'Rule Name',
        field: 'name',
        value: true
      },
      {
        title: 'Service Type',
        field: 'serviceTypes',
        value: true
      },
      {
        title: 'Action Type',
        field: 'actionTypes',
        value: true
      },
      {
        title: 'Process Type',
        field: 'processTypes',
        value: true
      },
      {
        title: 'Client/Contract/Brand',
        field: 'nodeHierarchy',
        value: true
      },
      {
        title: 'Priority',
        field: 'priorities',
        value: true
      },
      {
        title: 'Region',
        field: 'regions',
        value: true
      },
      {
        title: 'Model',
        field: 'models',
        value: true
      },
      {
        title: 'Trigger Point',
        field: 'triggerPoint',
        value: true
      },
      {
        title: 'Template',
        field: 'templateName',
        value: true
      },
      {
        title: 'Rule Notes',
        field: 'notes',
        value: true
      },
      {
        title: 'Is Active',
        field: 'isActive',
        value: true
      }
    ]
  }
];
  
export const SERVICE_PRESENTATION_OPTIONS = [
  { value: '1', label: '1 - Excellent' },
  { value: '2', label: '2 - Good' },
  { value: '3', label: '3 - Fair' },
  { value: '4', label: '4 - Poor' },
  { value: '5', label: '5 - Very Poor' }
];

export const LABEL_STYLES = [
  { value: 'None', label: 'None', bgColor: 'transparent' },
  { value: 'Info', label: 'Info', iconName: 'Info', bgColor: '#E9F2FF', iconType: 'info' },
  { value: 'Note', label: 'Note', iconName: 'Description', bgColor: '#F3F0FF', iconType: 'secondary' },
  { value: 'Tick', label: 'Tick', iconName: 'CheckCircle', bgColor: '#DCFFF1', iconType: 'success' },
  { value: 'Warn', label: 'Warn', iconName: 'Warning', bgColor: '#FFF7D6', iconType: 'warning' },
  { value: 'Critical', label: 'Critical', iconName: 'Cancel', bgColor: '#FFECEB', iconType: 'error' }
];

export const PART_TYPE_OPTIONS = [
  { value: 'any', label: 'Any' },
  { value: 'serialised', label: 'Serialised' },
  { value: 'consumable', label: 'Consumable' }
];

export const PART_ACTION_CONDITION_OPERATORS = [
  { value: '=', label: '=' },
  { value: '>=', label: '>' },
  { value: '<=', label: '<' }
];

export const PART_ACTION_CONDITION_JOIN_OPTIONS = [
  { value: 'OR', label: 'OR' },
  { value: 'AND', label: 'AND' }
];